/** @module ArticlePageLayout
 *  @desc Renders parameters for `head` page part (title, meta-data, etc).
 *  @since 2023.03.01, 15:46
 *  @changed 2023.03.01, 16:16
 */

import * as React from 'react';
import classnames from 'classnames';

import { HtmlHeader, THtmlHeaderProps } from '../HtmlHeader';
import { PageContent } from '../PageContent';

import styles from './ArticlePageLayout.module.scss';

export interface TArticlePageLayoutProps extends THtmlHeaderProps {
  className?: string;
  children?: React.ReactNode;
  paddedPanel?: boolean;
  stretchedPanelWrapper?: boolean;
}

export function ArticlePageLayout(props: TArticlePageLayoutProps): JSX.Element {
  // NOTE: Get props from nextjs (as `pageProps`)
  const { className, children, paddedPanel, stretchedPanelWrapper, ...restProps } = props;
  // prettier-ignore
  return (
    <>
      <HtmlHeader {...restProps} />
      {/* Other app layout elements should came here */}
      <PageContent
        className={classnames(className, styles.content)}
        stretched={stretchedPanelWrapper}
        paddedPanel={paddedPanel}
        centered
        >
        {children}
      </PageContent>
    </>
  );
}
