/** @module CasterView
 *  @since 2023.03.06, 16:17
 *  @changed 2023.03.06, 21:40
 */

import React from 'react';
import { Stack, Typography } from '@mui/material';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import config from '@/config';
import { LoaderSplash } from '@/ui';
import { compose, errorToString } from '@/helpers';
import { useCasterOptionsStore } from '@/store';
import { CasterViewScripts } from './CasterViewScripts';

import styles from './CasterView.module.scss';
import { PanoPlayer } from './PanoPlayer';

interface TProps {
  className?: string;
  // mode?: TCasterViewMode;
}

// // TODO: Move parameters to config/store?
const panoSkinUrl = config.build.isDev
  ? 'http://localhost:3001/kuskovo/002/' // Local dev server (eg: `http://localhost:3001/kuskovo/002/index.xml`)
  : '/kuskovo/002/'; // 'https://360caster.com/kuskovo/002/'; // Test server (eg: `https://360caster.com/kuskovo/002/index.xml`)
const socketsUrl = config.build.isDev
  ? 'http://localhost:8082/' // Local socket.io server
  : '/'; // Production sockets address (`https://360caster.com:8082/`)
const socketsPath = '/appId002/';

const uniquePanoId = 'pano2vr-container'; // TODO: Generate unique id dynamicaly?

function CasterViewComponent(props: TProps): JSX.Element {
  const { className } = props;

  // TODO: Use caster options store...
  const casterOptionsStore = useCasterOptionsStore();
  const {
    viewMode,
    // waiting,
    inited,
  } = casterOptionsStore;

  // Load required scripts (from `/public/assets/js/vr`, see `src/components/CasterView/CasterViewScripts.tsx`, with errors processing)...
  const [scriptsLoaded, setScriptsLoaded] = React.useState(false);
  const onScriptsLoaded = React.useCallback(() => setScriptsLoaded(true), []);
  const [error, setError] = React.useState<Error | undefined>();
  const onScriptsLoadError = React.useCallback(
    (errObj: Error & React.SyntheticEvent & { srcElement?: { src?: string } }) => {
      const src = errObj?.srcElement?.src;
      const error = new Error('VR scripts loading error.');
      // eslint-disable-next-line no-console
      console.error('[CasterView] onScriptsLoadError', { errObj, error, src });
      // debugger; // eslint-disable-line no-debugger
      setError(error);
    },
    [],
  );

  // Detect when the container will be already rendered...
  const [panoRef, setPanoRef] = React.useState<HTMLDivElement | undefined>();

  // Create pano2vr player when all the scripts is ready...
  const panoPlayer = React.useMemo(
    () => new PanoPlayer({ uniquePanoId, panoSkinUrl, viewMode, socketsUrl, socketsPath }),
    [viewMode],
  );
  React.useEffect(() => {
    if (scriptsLoaded && panoRef) {
      panoPlayer.start();
      return () => panoPlayer.finish();
    }
  }, [scriptsLoaded, panoRef, panoPlayer]);

  const isLoading = !inited || (!scriptsLoaded && !error);

  // prettier-ignore
  return (
    <>
      <CasterViewScripts
        onScriptsLoaded={onScriptsLoaded}
        onScriptsLoadError={onScriptsLoadError}
        skinUrlsPrefix={panoSkinUrl}
      />
      <Stack className={classnames(className, styles.root)}>
        {error && (
          <Typography textAlign="center" color="error">
            {errorToString(error)}
          </Typography>
        )}
        {/* NOTE: Using div container to use ref attribute. */}
        <div
          ref={setPanoRef}
          id={uniquePanoId}
          className={classnames(styles.panoPlayer, !isLoading && !error && styles.ready)}
        />
        <LoaderSplash
          className={styles.loaderSplash}
          show={isLoading}
          spinnerSize="large"
          bg="black"
          mode="cover"
          fullSize
        />
      </Stack>
    </>
  );
}

export const CasterView = compose(
  // prettier-ignore
  observer,
)(CasterViewComponent) as typeof CasterViewComponent;
