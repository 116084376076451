/** @module generic-includes
 *  @since 2023.01.26, 23:43
 *  @changed 2023.01.26, 23:43
 */

// Import locally installed font (instead link external cdn url, defined in `src/config/site.ts`).
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// NOTE: Does nextjs already includes required polyfills?
// import './polyfills'

export {}; // Calm tsserver for empty module exports
