/** @module withCasterOptionsStateWrapper
 *  @desc Wrapping any component (with TestWrapper)
 *  @since 2023.03.07, 14:26
 *  @changed 2023.03.07, 14:26
 */

import React from 'react';

import {
  CasterOptionsStateWrapper,
  TCasterOptionsStateWrapperParams,
} from './CasterOptionsStateWrapper';

export interface TWithCasterOptionsStateWrapperProps extends JSX.IntrinsicAttributes {
  error?: Error;
  isWaiting?: boolean;
  isInited?: boolean;
}

export function withCasterOptionsStateWrapperFabric<P extends JSX.IntrinsicAttributes>(
  params: TCasterOptionsStateWrapperParams = {},
): (
  Component: React.ComponentType<P & TWithCasterOptionsStateWrapperProps>,
) => (props: P) => JSX.Element {
  const {
    wrapperClassName,
    contentClassName,
    errorClassName,
    showErrorInWrapper,
    showContentBehindLoader,
  } = params;
  return function withCasterOptionsStateWrapper<P extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<P>,
  ) {
    return function WithCasterOptionsStateWrapper(props: P) {
      return (
        <CasterOptionsStateWrapper
          className={wrapperClassName}
          contentClassName={contentClassName}
          errorClassName={errorClassName}
          showErrorInWrapper={showErrorInWrapper}
          showContentBehindLoader={showContentBehindLoader}
        >
          <Component {...props} />
        </CasterOptionsStateWrapper>
      );
    };
  };
}
