/** @module FullScreenPageLayout
 *  @desc Renders parameters for `head` page part (title, meta-data, etc).
 *  @since 2023.03.01, 15:46
 *  @changed 2023.03.01, 16:16
 */

import * as React from 'react';

import { HtmlHeader, THtmlHeaderProps } from '../HtmlHeader';

export interface TFullScreenPageLayoutProps extends THtmlHeaderProps {
  // className?: string;
  children?: React.ReactNode;
}

export function FullScreenPageLayout(props: TFullScreenPageLayoutProps): JSX.Element {
  // NOTE: Get props from nextjs (as `pageProps`)
  const { children, ...restProps } = props;
  // prettier-ignore
  return (
    <>
      <HtmlHeader {...restProps} />
      {children}
    </>
  );
}
