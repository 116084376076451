/** @module Caster
 *  @since 2023.03.01, 15:55
 *  @changed 2023.03.07, 18:28
 */

import React from 'react';
import { Stack } from '@mui/material';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import { parseQuery } from '@/helpers';
import { withThemeWrapperFabric } from '@/layout';
import { withCasterOptionsStateWrapperFabric } from '@/layout/state-wrappers/CasterOptionsStateWrapper';
import { compose } from '@/helpers';
import { useCasterOptionsStore, withCasterOptionsStoreWrapper } from '@/store';

import { CasterHeader } from './CasterHeader';
import { CasterFooter } from './CasterFooter';
import { CasterView } from '@/components/CasterView';
import { CasterVideoPreview } from '@/components/CasterVideoPreview';

import styles from './Caster.module.scss';

interface TProps {
  className?: string;
}

// TODO 2023.03.06, 23:20 -- Add splash loader/errors wrapper based on CasterOptionsStore.

function CasterContent({ useVideo }: { useVideo?: boolean }): JSX.Element {
  return (
    <>
      <CasterHeader className={classnames(styles.topPanel)} />
      <Stack className={classnames(styles.mainBox)} flex={1}>
        <CasterView />
        {useVideo && <CasterVideoPreview className={classnames(styles.previewWindow)} />}
      </Stack>
      <CasterFooter className={classnames(styles.bottomPanel)} useSwitchButtons />
    </>
  );
}

function CasterComponent(props: TProps): JSX.Element {
  const { className } = props;
  const casterOptionsStore = useCasterOptionsStore();
  const { useVideo, inited } = casterOptionsStore;
  React.useEffect(() => {
    const params = parseQuery(window.location.search);
    const viewMode = params.casterViewMode; // as TCasterViewMode | undefined;
    if (viewMode) {
      if (viewMode === 'guide' || viewMode === 'visitor') {
        casterOptionsStore.setViewMode(viewMode);
      } else {
        const error = new Error('Invalid value for viewMode: ' + viewMode);
        console.error('[CasterOptionsStoreWrapper] Effect error', { error }); // eslint-disable-line no-console
      }
    }
    casterOptionsStore.initFinished();
    // casterOptionsStore.initRejected(new Error('test')); // DEBUG
  }, [casterOptionsStore]);
  return (
    <Stack className={classnames(className, styles.root)}>
      {inited && <CasterContent useVideo={useVideo} />}
    </Stack>
  );
}

const themeWrapper =
  withThemeWrapperFabric && withThemeWrapperFabric({ mode: 'dark', fullSize: true });
/* // TODO: Check why withThemeWrapperFabric sometimes (on the first render in
 * // the nextjs server) isn't initialized here (due do dependencies loop?)
 * console.log('[Caster] themeWrapper test', { themeWrapper, withThemeWrapperFabric, list });
 */

export const Caster = compose(
  observer, // NOTE: The observer should be applied last
  withCasterOptionsStateWrapperFabric({ showContentBehindLoader: true }),
  themeWrapper,
  withCasterOptionsStoreWrapper, // Create store context root
)(CasterComponent) as typeof CasterComponent;
