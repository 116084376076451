/** @module StoreWrapper
 *  @since 2023.03.01, 19:36
 *  @changed 2023.03.01, 19:36
 */

import React from 'react';

import { appStore } from './stores';
import { rootStore } from './RootStore';
import { RootStoreProvider } from './storeContext';

interface StoreWrapperProps {
  children?: React.ReactNode;
}

export function StoreWrapper(props: StoreWrapperProps): JSX.Element {
  const { children } = props;
  // NOTE: Demo: This initalization should be processed in config/app initialization module.
  React.useEffect(() => {
    setTimeout(() => {
      // NOTE: Demo: Emulating succesfull initalization finish.
      appStore.initFinished();
      // // NOTE: Demo: Emulate the failure of the initalization process (only error will be displayed).
      // appStore.initRejected(new Error('Test error'));
    }, 500);
  }, []);
  // TODO: Use compose?
  return <RootStoreProvider value={rootStore}>{children}</RootStoreProvider>;
}
