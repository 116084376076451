/** @module TestPage
 *  @since 2023.02.20, 23:31
 *  @changed 2023.03.01, 15:36
 */

// import React from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

// import * as buildConfig from '@/config/build';
// import { compose } from '@/helpers';
import { ArticlePageLayout } from '@/layout';
import { subPageTitle } from '@/helpers';
import { useAppStore } from '@/store';
import {
  AppStateWrapper,
  // withAppStateWrapperFabric,
} from '@/layout/state-wrappers/AppStateWrapper';

function TestPageComponent(): JSX.Element {
  const pageTitle = 'Test page';
  const title = subPageTitle(pageTitle);
  // NOTE: Demo: Using app store
  const appStore = useAppStore();
  const { inited, error } = appStore;
  return (
    <ArticlePageLayout title={title}>
      <AppStateWrapper>
        <Stack alignItems="center">{pageTitle}</Stack>
        <Stack alignItems="center">Inited: {inited ? 'yes' : error ? 'error' : 'no'}</Stack>
      </AppStateWrapper>
    </ArticlePageLayout>
  );
}

export const TestPage = observer(TestPageComponent);

/* // NOTE: Demo: Applying multiple wrappers...
 * export const TestPage = compose(
 *   observer,
 *   // // NOTE: Don't use wrappers on the very top app level (outside basic layout): It can be rendered around essential wrappers like toast and theme providers
 *   // withAppStateWrapperFabric({ showContentBehindLoader: true }),
 * )(TestPageComponent);
 */
