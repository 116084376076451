/** @module withAppStateWrapper
 *  @desc Wrapping any component (with TestWrapper)
 *  @since 2023.02.14, 17:35
 *  @changed 2023.02.17, 00:43
 */

import React from 'react';

import { AppStateWrapper, TAppStateWrapperParams } from './AppStateWrapper';

export interface TWithAppStateWrapperProps extends JSX.IntrinsicAttributes {
  error?: Error;
  isWaiting?: boolean;
  isInited?: boolean;
}

export function withAppStateWrapperFabric<P extends JSX.IntrinsicAttributes>(
  params: TAppStateWrapperParams = {},
): (Component: React.ComponentType<P & TWithAppStateWrapperProps>) => (props: P) => JSX.Element {
  const {
    wrapperClassName,
    contentClassName,
    errorClassName,
    showErrorInWrapper,
    showContentBehindLoader,
  } = params;
  return function withAppStateWrapper<P extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<P>,
  ) {
    return function TestWrapper(props: P) {
      return (
        <AppStateWrapper
          className={wrapperClassName}
          contentClassName={contentClassName}
          errorClassName={errorClassName}
          showErrorInWrapper={showErrorInWrapper}
          showContentBehindLoader={showContentBehindLoader}
        >
          <Component {...props} />
        </AppStateWrapper>
      );
    };
  };
}
