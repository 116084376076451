/** @module CasterFooterIconButton
 *  @since 2023.03.01, 22:00
 *  @changed 2023.03.02, 18:34
 */

import React from 'react';
import { Box, Stack } from '@mui/material';
import classnames from 'classnames';
import { TCasterFooterButtonProps } from './CasterFooterButtonTypes';

import styles from './CasterFooterIconButton.module.scss';

export function CasterFooterIconButton(props: TCasterFooterButtonProps) {
  const {
    className,
    text,
    icon,
    checked,
    checkable,
    clickable,
    badgeNumber,
    theme,
    onToggle,
    onClick,
  } = props;
  const hasBadge = badgeNumber != null && !!badgeNumber;
  const hasIcon = !!icon;
  const Icon = hasIcon && icon;
  const component = checkable ? 'a' : 'span';
  const containerClassName = classnames(
    className,
    styles.root,
    checkable && styles.checkable,
    clickable && styles.clickable,
    checkable && !checked && styles.unchecked,
    hasIcon && styles.hasIcon,
    theme && styles['theme-' + theme],
  );
  const handleToggle = React.useCallback(() => {
    if (onToggle) {
      onToggle(!checked);
    }
  }, [onToggle, checked]);
  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  const clickHandler = React.useMemo(() => {
    return checkable ? handleToggle : clickable ? handleClick : null;
  }, [checkable, clickable, handleToggle, handleClick]);
  // prettier-ignore
  return (
    <Stack
      className={containerClassName}
      flexDirection="row"
      alignItems="center"
      component={component}
      onClick={clickHandler}
    >
      <Box className={styles.background} />
      {hasIcon && (
        <Stack className={styles.icon} flexDirection="row" alignItems="center">
          <Icon className={styles.iconSymbol} />
          {checkable && !checked && <Box className={styles.crossedIcon} />}
        </Stack>
      )}
      {!!text && <Box className={styles.text}>{text}</Box>}
      {hasBadge && <Box className={styles.badge}>{badgeNumber}</Box>}
    </Stack>
  );
}
