/** @module CasterFooterSwitchButton
 *  @since 2023.03.02, 14:17
 *  @changed 2023.03.02, 18:19
 */

import React from 'react';
import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import classnames from 'classnames';

import { TCasterFooterButtonProps } from './CasterFooterButtonTypes';

import styles from './CasterFooterSwitchButton.module.scss';

export function CasterFooterSwitchButton(props: TCasterFooterButtonProps) {
  const {
    className,
    text,
    icon,
    checked,
    checkable,
    clickable,
    badgeNumber,
    theme,
    onToggle,
    onClick,
  } = props;
  const hasBadge = badgeNumber != null && !!badgeNumber;
  const hasIcon = !checkable && !!icon;
  const Icon = hasIcon && icon;
  const component = clickable ? 'a' : 'span';
  const containerClassName = classnames(
    className,
    styles.root,
    checkable && styles.checkable,
    clickable && styles.clickable,
    checkable && !checked && styles.unchecked,
    hasIcon && styles.hasIcon,
    theme && styles['theme-' + theme],
  );
  const handleToggle = React.useCallback(
    (_ev: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (onToggle) {
        onToggle(value);
      }
    },
    [onToggle],
  );
  const handleClick = React.useCallback(() => {
    /* console.log('[CasterFooterSwitchButton] handleClick', { ev, onClick });
     * debugger;
     */
    if (onClick && clickable) {
      onClick();
    }
  }, [onClick, clickable]);
  const checkableContent = React.useMemo(
    () =>
      checkable ? (
        <FormControlLabel
          className={styles.switchLabel}
          control={
            <Switch
              className={styles.switch}
              checked={checked}
              color="default"
              onChange={handleToggle}
            />
          }
          label={text}
        />
      ) : null,
    [checkable, checked, text, handleToggle],
  );
  // prettier-ignore
  return (
    <Stack className={containerClassName} flexDirection="row" alignItems="center" component={component} onClick={handleClick}>
      <Box className={styles.background} />
      {checkableContent}
      {hasIcon && (
        <Stack className={styles.icon} flexDirection="row" alignItems="center">
          <Icon className={styles.iconSymbol} />
        </Stack>
      )}
      {!checkable && !!text && (
        <Box className={styles.text}>
          {text}
        </Box>
      )}
      {hasBadge && (
        <Box className={styles.badge}>
          {badgeNumber}
        </Box>
      )}
    </Stack>
  );
}
