/** @module CasterHeader
 *  @since 2023.03.02, 18:56
 *  @changed 2023.03.02, 19:09
 */

// import React from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import classnames from 'classnames';
import {
  // ViewInAr,
  // ThreeDRotation,
  // Mic,
  // Videocam,
  // People,
  // QuestionAnswer,
  // Close,
  Sensors,
  Menu,
  Window,
} from '@mui/icons-material';
import { observer } from 'mobx-react-lite';

import { useCasterOptionsStore } from '@/store';

import styles from './CasterHeader.module.scss';

interface TProps {
  className?: string;
}

export function CasterHeaderComponent(props: TProps) {
  const { className } = props;
  const _casterOptionsStore = useCasterOptionsStore();
  // prettier-ignore
  return (
    <Stack className={classnames(className, styles.root)} flexDirection="row" gap={2}>
      <Stack
        className={classnames(styles.buttons, styles.leftGroup)}
        flex={1}
        flexDirection="row"
        justifyContent="flex-start"
      >
        <IconButton size="small">
          <Menu />
        </IconButton>
      </Stack>
      <Stack
        className={classnames(styles.buttons, styles.rightGroup)}
        flexDirection="row"
        justifyContent="flex-end"
      >
        <IconButton size="small">
          <Sensors />
        </IconButton>
        <Button startIcon={<Window />} size="small">
          View
        </Button>
      </Stack>
    </Stack>
  );
}

export const CasterHeader = observer(CasterHeaderComponent);
