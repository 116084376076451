import { useContext } from 'react';
import { injectStores } from '@mobx-devtools/tools';

import * as buildConfig from '@/config/build';
import { RootStore, rootStore } from './RootStore';
import { makeStoreContext } from '@/helpers';

// DEBUG: Inject stores for mobx-devtool
if (buildConfig.isDevBrowser) {
  injectStores({
    rootStore,
  });
}

// TODO: Use AsyncTrunk

const storeContext = makeStoreContext<RootStore>(rootStore);
export const {
  StoreContext: RootStoreContext,
  StoreProvider: RootStoreProvider,
  useStore: useRootStore,
} = storeContext;

// // Context...
// export const StoreContext = createContext(rootStore);
// export const StoreProvider = StoreContext.Provider;
//
// // Use root store
// export const useStore = () => useContext(StoreContext);

// Export specific store selectors (`useAppStore`, ...)?
export const useAppStore = () => useContext(RootStoreContext).app;
