/** @module AppStore
 *  @since 2023.03.01, 15:30
 *  @changed 2023.03.01, 19:32
 */

import {
  makeObservable,
  // observable,
  // action,
} from 'mobx';

import { InitiableStoreBase } from '../base';

// NOTE: See demo code in `src/store/StoreWrapper.tsx`

// TODO: Use wrapper or class ancestor to implement 'with init defer' functionality

export class AppStore extends InitiableStoreBase {
  // TODO: Implement essential app properties here
  // @observable test = false;

  constructor() {
    super();
    makeObservable(this);
  }
}

// TODO: To use singleton to use directly from any place of the app?
export const appStore = new AppStore();
