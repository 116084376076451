/** @module CasterFooter
 *  @since 2023.03.01, 21:48
 *  @changed 2023.03.02, 19:30
 */

import React from 'react';
import { Stack } from '@mui/material';
import classnames from 'classnames';
import {
  ViewInAr,
  // ThreeDRotation,
  Mic,
  Videocam,
  People,
  QuestionAnswer,
  // Close,
} from '@mui/icons-material';
import { observer } from 'mobx-react-lite';

import { useCasterOptionsStore } from '@/store';

import { CasterFooterIconButton, CasterFooterSwitchButton } from './CasterFooterButton';

import styles from './CasterFooter.module.scss';

interface TProps {
  className?: string;
  useSwitchButtons?: boolean;
}

export function CasterFooterComponent(props: TProps) {
  const { className, useSwitchButtons } = props;
  const ButtonComponent = useSwitchButtons ? CasterFooterSwitchButton : CasterFooterIconButton;
  const casterOptionsStore = useCasterOptionsStore();
  const { useVR, useAudio, useVideo } = casterOptionsStore;
  // DEBUG: Click handler example
  const handleClick = React.useCallback(() => {
    // console.log('[CasterFooter] handleClick');
  }, []);
  // prettier-ignore
  return (
    <Stack className={classnames(className, styles.root)} flexDirection="row" gap={2}>
      <Stack
        className={classnames(styles.buttons, styles.leftGroup)}
        flex={1}
        flexDirection="row"
        justifyContent="flex-start"
      >
        <ButtonComponent text="VR" icon={ViewInAr} checkable checked={useVR} onToggle={casterOptionsStore.setUseVR} />
        <ButtonComponent text="Audio" icon={Mic} checkable checked={useAudio} onToggle={casterOptionsStore.setUseAudio} />
        <ButtonComponent text="Video" icon={Videocam} checkable checked={useVideo} onToggle={casterOptionsStore.setUseVideo} />
        <ButtonComponent text="Participants" icon={People} badgeNumber={0} onClick={handleClick} />
        <ButtonComponent text="Chat" icon={QuestionAnswer} clickable onClick={handleClick} />
      </Stack>
      <Stack
        className={classnames(styles.buttons, styles.rightGroup)}
        flexDirection="row"
        justifyContent="flex-end"
      >
        <ButtonComponent
          text="End"
          // icon={Close}
          theme="alert"
          clickable
          onClick={handleClick}
        />
      </Stack>
    </Stack>
  );
}

export const CasterFooter = observer(CasterFooterComponent);
