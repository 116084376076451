/** @module AppWrapper
 *  @since 2023.02.20, 23:41
 *  @changed 2023.03.01, 18:57
 */

import React from 'react';

import { StoreWrapper } from '@/store';
import { CoreWrapper, ThemeWrapper } from '@/layout';

// TODO: Import control nodes/hooks from `features` folder.

interface AppWrapperProps {
  children?: React.ReactNode;
}

export function AppWrapper(props: AppWrapperProps): JSX.Element {
  const { children } = props;
  // TODO: To use composition?
  return (
    <>
      <StoreWrapper>
        <CoreWrapper>
          <ThemeWrapper fullSize>
            {/* TODO: Expose root control nodes or use custom hooks? */}
            {children}
          </ThemeWrapper>
        </CoreWrapper>
      </StoreWrapper>
    </>
  );
}
