import { makeObservable, observable, action } from 'mobx';

import { defaultCasterViewMode, TCasterViewMode } from '@/core/types';

import { InitiableStoreBase } from '../base';

export class CasterOptionsStore extends InitiableStoreBase {
  @observable useVR = true;
  @observable useAudio = false;
  @observable useVideo = true;
  @observable showParticipants = false;
  @observable showChat = false;

  @observable viewMode: TCasterViewMode = defaultCasterViewMode;

  constructor() {
    super();
    makeObservable(this);
  }

  @action.bound setUseVR(useVR: boolean) {
    this.useVR = useVR;
  }
  @action.bound setUseAudio(useAudio: boolean) {
    this.useAudio = useAudio;
  }
  @action.bound setUseVideo(useVideo: boolean) {
    this.useVideo = useVideo;
  }
  @action.bound setShowParticipants(showParticipants: boolean) {
    this.showParticipants = showParticipants;
  }
  @action.bound setShowChat(showChat: boolean) {
    this.showChat = showChat;
  }

  @action.bound setViewMode(viewMode: TCasterViewMode) {
    this.viewMode = viewMode;
  }
}

// TODO: To use singleton to use directly from any place of the app?
export const casterOptionsStore = new CasterOptionsStore();
