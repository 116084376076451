/** @module CasterViewScripts
 *  @since 2023.03.06, 17:26
 *  @changed 2023.03.07, 18:18
 */

import React from 'react';
import Script from 'next/script';

import { TCasterViewMode } from '@/core/types';

interface TProps {
  mode?: TCasterViewMode;
  skinUrlsPrefix: string;
  onScriptsLoaded?: () => void;
  onScriptsLoadError?: (errObj: Error | React.SyntheticEvent) => void;
}

const defaultScriptUrls = [
  '/assets/js/vr/webxr/three.min.js',
  '/assets/js/vr/webxr/webxr-polyfill.min.js',
  // '/assets/js/vr/socketio/socket.io.min.js', // Socket.io imported from node modules
  '/assets/js/vr/player.js', // Pano2VR player 6.1.10/18007 - Prefix:-webkit-, CSS 3D available, WebGL available
];

export function CasterViewScripts(props: TProps): JSX.Element {
  const { onScriptsLoaded, onScriptsLoadError, skinUrlsPrefix } = props;
  const scriptUrls = React.useMemo<string[]>(() => {
    // Add skin.js script url from vr server...
    const skinUrl = skinUrlsPrefix + 'skin.js';
    return [...defaultScriptUrls, skinUrl];
  }, [skinUrlsPrefix]);
  // NOTE: Use state in the case on any problems during hmr refresh
  const memoScripts = React.useMemo<string[]>(() => [], []);
  const onLoad = React.useCallback(
    (id: string) => {
      if (!memoScripts.includes(id)) {
        memoScripts.push(id);
        const expectedScriptsCount = scriptUrls.length;
        if (memoScripts.length >= expectedScriptsCount && onScriptsLoaded) {
          onScriptsLoaded();
        }
      }
    },
    [memoScripts, onScriptsLoaded, scriptUrls],
  );
  return (
    <>
      {scriptUrls.map((url) => (
        <Script key={url} src={url} onReady={() => onLoad(url)} onError={onScriptsLoadError} />
      ))}
    </>
  );
}
