/** @module CasterPage
 *  @since 2023.03.01, 15:30
 *  @changed 2023.03.01, 15:53
 */

// import { observer } from 'mobx-react-lite';

import { AppStateWrapper, FullScreenPageLayout } from '@/layout';
import { subPageTitle } from '@/helpers';
import { Caster } from '@/components';

export function CasterPage(): JSX.Element {
  const pageTitle = 'Caster page';
  const title = subPageTitle(pageTitle);
  return (
    <FullScreenPageLayout title={title}>
      <AppStateWrapper>
        <Caster />
      </AppStateWrapper>
    </FullScreenPageLayout>
  );
}

// export const CasterPage = observer(CasterPageComponent);
