/** @module config.site
 *  @since 2023.02.20, 23:15
 *  @changed 2023.02.20, 23:15
 */

export const htmlLang = 'en';
export const titleDelim = ' — ';
export const siteUrl = 'https://360caster.com/';
export const title = '360caster';
export const descr = '360caster';
export const keywords = ['360caster'];
export const faviconUrl = '/static/favicon.ico';
export const faviconPngUrl = '/static/favicon-120x120.png';
export const faviconSvgUrl = '/static/favicon.svg';
export const opImageUrl = '/static/logo-256.png';
export const opImageWidth = 256;
export const opImageHeight = 256;

export const notFoundTitle = 'Page not found';

// NOTE: Include extrnal font cdn link in `src/pages/_document.tsx` or import
// installed font in `src/core/global/global-includes.ts`. Remain empty value
// to omit urls.
// export const fontUrl =
//   'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';
export const fontUrl = undefined;
