/** @module _app
 *  @desc Root application component
 *  @since 2023.02.20, 23:17
 *  @changed 2023.02.20, 23:17
 */

import { AppProps } from 'next/app';
import * as mobx from 'mobx';

import * as buildConfig from '@/config/build';

import '@/core/global/global-includes';

import { AppWrapper } from '@/core';

import '@/core/global/global-styles.scss';

// DEBUG: Expose mobx for mobx_prettier browser plugin
if (buildConfig.isDevBrowser) {
  window.mobx = mobx;
}

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      {/* Redux store (unused?)
      <Provider store={store}>
      </Provider>
      */}
      <AppWrapper>
        <Component {...pageProps} />
      </AppWrapper>
    </>
  );
}
