import React from 'react';

import { casterOptionsStore } from './CasterOptionsStore';
import { CasterOptionsStoreProvider } from './CasterOptionsStoreContext';

interface TCasterOptionsStoreWrapperProps {
  children?: React.ReactNode;
}
export function CasterOptionsStoreWrapper(props: TCasterOptionsStoreWrapperProps): JSX.Element {
  const { children } = props;
  return (
    <CasterOptionsStoreProvider value={casterOptionsStore}>{children}</CasterOptionsStoreProvider>
  );
}

export function withCasterOptionsStoreWrapper<P extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<P>,
) {
  return function CasterOptionsStoreWrapped(props: P) {
    return (
      <CasterOptionsStoreWrapper>
        <Component {...props} />
      </CasterOptionsStoreWrapper>
    );
  };
}
