/** @module withArticlePageLayout
 *  @desc Wrapping any component (with TestWrapper)
 *  @since 2023.02.14, 17:35
 *  @changed 2023.03.01, 15:52
 */

// TODO: It's not a good idea to use ArticlePageLayout via HOC as title what
// can be used here may be required internationalization, so it's possible to
// be aken from the store.

import React from 'react';

import { ArticlePageLayout, TArticlePageLayoutProps } from './ArticlePageLayout';

export interface TWithArticlePageLayoutProps extends JSX.IntrinsicAttributes {
  title?: string;
}

export function withArticlePageLayoutFabric<P extends JSX.IntrinsicAttributes>(
  wrapperProps: TArticlePageLayoutProps = {},
): (
  Component: React.ComponentType<P & TWithArticlePageLayoutProps>,
) => (props: P & TWithArticlePageLayoutProps) => JSX.Element {
  return function withArticlePageLayout<P extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<P>,
  ) {
    return function TestWrapper(props: P & TWithArticlePageLayoutProps) {
      const { title, ...restProps } = props;
      return (
        <ArticlePageLayout title={title} {...wrapperProps}>
          <Component {...(restProps as P)} />
        </ArticlePageLayout>
      );
    };
  };
}
