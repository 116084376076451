export default {
  INFO: "Generated by next.config from \"css-params-source.js\"",
  primaryColor: "#147",
  secondaryColor: "#d04",
  brightErrorColor: "#f00",
  errorColor: "#f00",
  dangerColor: "#c33",
  warnColor: "#f73",
  successColor: "#593",
  infoColor: "#147",
  textColor: "#000",
  defaultTextColor: "#000",
  defaultLinkColor: "#003d9d",
  neutralColor: "#888",
  bootstrapDarkColor: "#212529",
  primaryMuiColors: {
    light: "#1e77d0",
    main: "#114477",
    dark: "#04111e",
    contrastText: "#ffffff"
  },
  primaryMuiColorsLight: "#1e77d0",
  primaryMuiColorsMain: "#114477",
  primaryMuiColorsDark: "#04111e",
  primaryMuiColorsContrastText: "#ffffff",
  secondaryMuiColors: {
    light: "#ff447e",
    main: "#dd0044",
    dark: "#770025",
    contrastText: "#ffffff"
  },
  secondaryMuiColorsLight: "#ff447e",
  secondaryMuiColorsMain: "#dd0044",
  secondaryMuiColorsDark: "#770025",
  secondaryMuiColorsContrastText: "#ffffff",
  neutralMuiColors: {
    light: "#bbbbbb",
    main: "#888888",
    dark: "#555555",
    contrastText: "#000000"
  },
  neutralMuiColorsLight: "#bbbbbb",
  neutralMuiColorsMain: "#888888",
  neutralMuiColorsDark: "#555555",
  neutralMuiColorsContrastText: "#000000",
  primaryColors: {
    "50": "#c3ddf6",
    "100": "#6aaaea",
    "200": "#1e77d0",
    "300": "#175da4",
    "400": "#14518d",
    "500": "#114477",
    "600": "#0e3761",
    "700": "#0b2a4a",
    "800": "#04111e",
    "900": "#000000",
    "950": "#000000"
  },
  primaryColors50: "#c3ddf6",
  primaryColors100: "#6aaaea",
  primaryColors200: "#1e77d0",
  primaryColors300: "#175da4",
  primaryColors400: "#14518d",
  primaryColors500: "#114477",
  primaryColors600: "#0e3761",
  primaryColors700: "#0b2a4a",
  primaryColors800: "#04111e",
  primaryColors900: "#000000",
  primaryColors950: "#000000",
  secondaryColors: {
    "50": "#ffffff",
    "100": "#ffaac4",
    "200": "#ff447e",
    "300": "#ff115a",
    "400": "#f6004c",
    "500": "#dd0044",
    "600": "#c3003c",
    "700": "#aa0034",
    "800": "#770025",
    "900": "#440015",
    "950": "#000000"
  },
  secondaryColors50: "#ffffff",
  secondaryColors100: "#ffaac4",
  secondaryColors200: "#ff447e",
  secondaryColors300: "#ff115a",
  secondaryColors400: "#f6004c",
  secondaryColors500: "#dd0044",
  secondaryColors600: "#c3003c",
  secondaryColors700: "#aa0034",
  secondaryColors800: "#770025",
  secondaryColors900: "#440015",
  secondaryColors950: "#000000",
  neutralColors: {
    "50": "#ffffff",
    "100": "#eeeeee",
    "200": "#bbbbbb",
    "300": "#a1a1a1",
    "400": "#959595",
    "500": "#888888",
    "600": "#7b7b7b",
    "700": "#6e6e6e",
    "800": "#555555",
    "900": "#3b3b3b",
    "950": "#080808"
  },
  neutralColors50: "#ffffff",
  neutralColors100: "#eeeeee",
  neutralColors200: "#bbbbbb",
  neutralColors300: "#a1a1a1",
  neutralColors400: "#959595",
  neutralColors500: "#888888",
  neutralColors600: "#7b7b7b",
  neutralColors700: "#6e6e6e",
  neutralColors800: "#555555",
  neutralColors900: "#3b3b3b",
  neutralColors950: "#080808",
  brandColors: {
    primary: "#147",
    primary_variant: "#04111e",
    primary_on: "#175da4",
    secondary: "#d04",
    secondary_variant: "#770025",
    secondary_on: "#ff115a",
    background: "#F8FEFC",
    background_on: "#003225",
    error: "#f00",
    error_variant: "#8E001A",
    error_on: "#F8FEFC",
    actionable: "#1890FF",
    warning: "#f73",
    dark_green: "#003224"
  },
  brandColorsPrimary: "#147",
  brandColorsPrimary_variant: "#04111e",
  brandColorsPrimary_on: "#175da4",
  brandColorsSecondary: "#d04",
  brandColorsSecondary_variant: "#770025",
  brandColorsSecondary_on: "#ff115a",
  brandColorsBackground: "#F8FEFC",
  brandColorsBackground_on: "#003225",
  brandColorsError: "#f00",
  brandColorsError_variant: "#8E001A",
  brandColorsError_on: "#F8FEFC",
  brandColorsActionable: "#1890FF",
  brandColorsWarning: "#f73",
  brandColorsDark_green: "#003224",
  bodyBgColor: "#f5f5f5",
  titleFont: "Roboto, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", Arial, sans-serif",
  defaultFont: "Roboto, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", Arial, sans-serif",
  defaultFontSize: 14,
  defaultFontSizePx: "14px",
  fontSize: 14,
  fontSizePx: "14px",
  fontSizeM: 14,
  fontSizeSm: 12,
  fontSizeSmPx: "12px",
  fontSizeXs: 10,
  fontSizeXsPx: "10px",
  fontSizeLg: 16,
  fontSizeLgPx: "16px",
  fontSizeXl: 18,
  fontSizeXlPx: "18px",
  fontSizeXxl: 22,
  fontSizeXxlPx: "22px",
  titleFontSize: 22,
  titleFontSizePx: "22px",
  defaultLineHeight: 1.3,
  defaultLineHeightPx: "1.3px",
  defaultFontWeight: 400,
  lightFontWeight: 300,
  breakpoints: {
    base: 360,
    sm: 512,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1600
  },
  breakpointsBase: 360,
  breakpointsBasePx: "360px",
  breakpointsSm: 512,
  breakpointsSmPx: "512px",
  breakpointsMd: 768,
  breakpointsMdPx: "768px",
  breakpointsLg: 1024,
  breakpointsLgPx: "1024px",
  breakpointsXl: 1280,
  breakpointsXlPx: "1280px",
  breakpoints2xl: 1600,
  breakpoints2xlPx: "1600px",
  breakpointsPre: {
    base: 359.98,
    sm: 511.98,
    md: 767.98,
    lg: 1023.98,
    xl: 1279.98,
    "2xl": 1599.98
  },
  breakpointsPreBase: 359.98,
  breakpointsPreBasePx: "359.98px",
  breakpointsPreSm: 511.98,
  breakpointsPreSmPx: "511.98px",
  breakpointsPreMd: 767.98,
  breakpointsPreMdPx: "767.98px",
  breakpointsPreLg: 1023.98,
  breakpointsPreLgPx: "1023.98px",
  breakpointsPreXl: 1279.98,
  breakpointsPreXlPx: "1279.98px",
  breakpointsPre2xl: 1599.98,
  breakpointsPre2xlPx: "1599.98px",
  breakpointsPx: {
    base: "360px",
    sm: "512px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1600px"
  },
  breakpointsPxBase: "360px",
  breakpointsPxSm: "512px",
  breakpointsPxMd: "768px",
  breakpointsPxLg: "1024px",
  breakpointsPxXl: "1280px",
  breakpointsPx2xl: "1600px",
  breakpointsPrePx: {
    base: "359.98px",
    sm: "511.98px",
    md: "767.98px",
    lg: "1023.98px",
    xl: "1279.98px",
    "2xl": "1599.98px"
  },
  breakpointsPrePxBase: "359.98px",
  breakpointsPrePxSm: "511.98px",
  breakpointsPrePxMd: "767.98px",
  breakpointsPrePxLg: "1023.98px",
  breakpointsPrePxXl: "1279.98px",
  breakpointsPrePx2xl: "1599.98px",
  wrapperMaxWidth: 1280,
  wrapperMaxWidthPx: "1280px",
  innerPadding: 4,
  innerPaddingPx: "4px",
  itemPadding: 8,
  itemPaddingPx: "8px",
  containerPadding: 16,
  containerPaddingPx: "16px",
  blockPadding: 24,
  blockPaddingPx: "24px",
  doublePadding: 32,
  doublePaddingPx: "32px",
  transitionTime: 250,
  transitionTimeMs: "250ms",
  animationTime: 500,
  animationTimeMs: "500ms",
  disappearTime: 1000,
  disappearTimeMs: "1000ms"
};