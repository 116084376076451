import React from 'react';
import { Paper } from '@mui/material';
import classnames from 'classnames';

import config from '@/config';
import { createMuiTheme, ThemeProvider, TMuiThemeMode } from '@/core/global/mui-theme';

import styles from './ThemeWrapper.module.scss';

export interface TThemeWrapperParams {
  mode?: TMuiThemeMode;
  fullSize?: boolean;
  className?: string;
}
export interface TThemeWrapperProps extends JSX.IntrinsicAttributes, TThemeWrapperParams {
  children: React.ReactNode;
}

export function ThemeWrapper(props: TThemeWrapperProps) {
  const { children, fullSize, className, mode = config.app.themeMode } = props;
  const theme = React.useMemo(() => createMuiTheme({ mode }), [mode]);
  const paperClassName = classnames(className, styles.paperContainer, fullSize && styles.fullSize);
  return (
    <>
      {/* Theme */}
      <ThemeProvider theme={theme}>
        {/* Basic page style
          See https://mui.com/material-ui/api/paper/
         */}
        <Paper className={paperClassName}>
          {/* Core content */}
          {children}
        </Paper>
      </ThemeProvider>
    </>
  );
}

export function withThemeWrapperFabric<P extends JSX.IntrinsicAttributes>(
  params: TThemeWrapperParams = {},
): (Component: React.ComponentType<P & TThemeWrapperProps>) => (props: P) => JSX.Element {
  const { className, fullSize, mode = config.app.themeMode } = params;
  return function withThemeWrapper<P extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<P>,
  ) {
    return function WithThemeWrapper(props: P) {
      return (
        <ThemeWrapper className={className} fullSize={fullSize} mode={mode}>
          <Component {...props} />
        </ThemeWrapper>
      );
    };
  };
}
