/** @module CasterVideoPreview
 *  @since 2023.03.06, 16:54
 *  @changed 2023.03.06, 16:54
 */

import { Box } from '@mui/material';
import classnames from 'classnames';

import styles from './CasterVideoPreview.module.scss';

interface TProps {
  className?: string;
}

export function CasterVideoPreviewComponent(props: TProps): JSX.Element {
  const { className } = props;
  // prettier-ignore
  return (
    <Box className={classnames(className, styles.root, styles.previewWindow)} />
  );
}

export const CasterVideoPreview = CasterVideoPreviewComponent;
