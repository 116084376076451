import { makeStoreContext } from '@/helpers';
import { casterOptionsStore, CasterOptionsStore } from './CasterOptionsStore';

// Create & export context
const storeContext = makeStoreContext<CasterOptionsStore>(casterOptionsStore);
export const {
  StoreContext: CasterOptionsStoreContext,
  StoreProvider: CasterOptionsStoreProvider,
  useStore: useCasterOptionsStore,
} = storeContext;
