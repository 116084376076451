/** @module mui-theme
 *  @since 2023.02.07, 20:07
 *  @changed 2023.03.01, 21:29
 *  @see:
 *  - [Migrating to v5: getting started - Material UI](https://mui.com/material-ui/migration/migration-v4/)
 *  - [Theming - Material UI](https://mui.com/material-ui/customization/theming/)
 */

import { ThemeProvider, createTheme, ThemeOptions, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import generatedCssParams from './css-params-generated';

export type TMuiThemeMode = 'light' | 'dark';
export const defaultMuiThemeMode: TMuiThemeMode = 'light';
export interface TMuiThemeParams {
  mode?: TMuiThemeMode;
}

function getMuiThemeOptions(params?: TMuiThemeParams) {
  const { mode = defaultMuiThemeMode } = params || {};
  const { primaryMuiColors, secondaryMuiColors, defaultFontSize } = generatedCssParams;
  const backgroundColor = mode === 'dark' ? '#000' : '#fff';
  const textColor = mode === 'dark' ? '#fff' : '#000';
  const background = {
    default: backgroundColor,
    paper: backgroundColor,
  };
  const text = {
    primary: textColor,
  };
  const palette: PaletteOptions = {
    primary: primaryMuiColors,
    secondary: secondaryMuiColors,
    background,
    text,
    // TODO:
    // - error
  };
  // @see https://mui.com/material-ui/customization/typography/
  const typography: TypographyOptions = {
    // TODO: To investigate the method of customizing mui theme font properties!
    fontSize: defaultFontSize,
    htmlFontSize: defaultFontSize,
    allVariants: {
      fontSize: defaultFontSize,
    },
    button: {
      textTransform: 'none',
    },
  };
  const options: ThemeOptions = {
    // direction: 'rtl', // NOTE: Right to left direction. Rememeber to add `dir="rtl"` attribute to body tag.

    palette,
    typography,

    // May be used in code:
    // - breakpoints
  };
  return options;
}

export function createMuiTheme(params?: TMuiThemeParams) {
  const options = getMuiThemeOptions(params);
  const theme = createTheme(options);
  /* // DEBUG: Use this data to extend ThemeOptions data above
   * console.log('[mui-theme]: createMuiTheme', {
   *   theme,
   * });
   */
  return theme;
}

export { ThemeProvider };
